<template>
  <div class="panel-left">
    <ApiErrorMessage :message="apiErrorMessage" />
    <!-- message -->
    <div class="panel-inr-box">
      <div class="messsge-search box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
        <div class="box-top-wrap-left">
          <div class="c-datepicker">
            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details="auto" v-model="date" :label="$t('posts.oldPostedMessages')"
                  append-icon="mdi-calendar-edit" solo dense readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker color="green darken-4" type="month" v-model="date" @input="menu2 = false"
                @change="fetchPosts"></v-date-picker>
            </v-menu>
          </div>
          <div class="btn-msg d-flex">
            <!--<v-btn elevation="0" class="mr-3" green>
          <v-icon class="mr-2">mdi-forum</v-icon>
          {{ $t("posts.chatWithDismentlars") }}
        </v-btn>-->
            <button
              :class="badgeCount !== 0 ? 'btn customBtn dark-gray-btn view-message' : 'btn customBtn dark-gray-btn'"
              elevation="0" @click="$router.push({ name: 'MessageCenter' }).catch((err) => { })">
              <v-icon :class="badgeCount !== 0 ? 'view-message-icon' : ''">mdi-email</v-icon>
              <span class="btn-text">{{ $t("posts.messages") }}</span>
              <v-badge color="red" class="mb-6" :content="badgeCount" v-if="badgeCount !== 0" />
            </button>
          <span v-if="$vuetify.breakpoint.width < 501" class="ml-1">
            <button @click="setDialog(false)" class="create-post-btn btn customBtn pattern-btn">
              <span>{{ $t("posts.createPost") }}</span>
            </button>
          </span>
          </div>
        </div>
        <div class="box-top-wrap-right">
          <span class="rd-post-search">
            <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
              :placeholder="$t('posts.searchForWords')" solo @input="searchPosts"
              append-icon="mdi-magnify"></v-text-field>
          </span>
          <span v-if="$vuetify.breakpoint.width > 500">
            <button @click="setDialog(false)" class="create-post-btn btn customBtn pattern-btn">
              <span>{{ $t("posts.createPost") }}</span>
            </button>
          </span>
        </div>
      </div>
      <!-- tables -->
      <div class="custom-card inside-model-box">
        <!-- :disable-sort="true" -->
        <div class="custom-box">
          <v-data-table class="color-light-green" :disable-sort="true" :headers="headers" :items="posts.data"
            fixed-header :single-expand="singleExpand" :expanded.sync="expanded" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <template v-slot:item.created_at="{ item }">
              <span v-if="!item.phone && (item.post_type == 0 || item.post_type == 2 || item.post_type == 3)"
                class="datebreak date-time">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                <span>{{ item.created_at | DateTimezoneFilter }}</span>
              </span>
              <span v-if="item.phone && item.post_type == 0" class="datebreak date-time error--text">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                {{ item.created_at |
                  DateTimezoneFilter }}</span>
              <span v-if="item.phone && item.post_type == 1" class="datebreak date-time blue--text">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                {{ item.created_at |
                  DateTimezoneFilter }}</span>
              <span v-if="!item.phone && item.post_type == 1" class="datebreak date-time blue--text">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                {{ item.created_at |
                  DateTimezoneFilter }}</span>
              <span class="rd-post-icon-content rd-user-name"
                v-if="item.owner && (item.post_type == 0 || item.post_type == 2 || item.post_type == 3)">
                <v-icon aria-hidden="false">
                  mdi-account-outline
                </v-icon>
                <span><b>By:</b>
                  {{
                    item.owner.name
                  }} {{ item.owner.location }}</span>
              </span>
              <span v-if="!item.owner && item.post_type == 0" class="error--text rd-user-phone rd-post-icon-content">
                <v-icon aria-hidden="false">
                  mdi-phone-outline
                </v-icon>
                {{
                  item.phone.substring(2) | USFormatNumber
                }}</span>
              <span v-if="item.owner && item.post_type == 1 && item.phone"
                class="blue--text rd-user-phone rd-post-icon-content">
                <v-icon aria-hidden="false">
                  mdi-phone-outline
                </v-icon>
                {{
                  item.phone.substring(2) | USFormatNumber
                }}</span>
            </template>
            <template v-slot:item.message="{ item }">
              <div v-if="!item.phone && !item.medias.length && item.post_type == 0" class="text-pre-wrap">{{
                item.message.replace('$0.00', 'Call') }}</div>
              <!-- post_type=2 => inventory post -->
              <div v-if="!item.phone && item.post_type == 2" class="text-pre-wrap post-with-image rd-post-with-image">
                <div class="post-with-images-img" v-if="item.medias.length">
                  <div @click="setImageSlider(item)">
                    <img :src="item.medias[0].url100" alt="">
                    <span class="click-on-it">Click on it</span>
                  </div>
                </div>
                <div class="post-with-images-text">
                  <span><span class="sale-label">For Sale</span><br />{{ item.message.replace('$0.00', 'Call')
                    }}</span>
                  <div class="text-right">
                    <!-- <v-btn height="35"
                      v-show="item.owner == null || item.owner.id != user.id && (item.post_type == 0 || item.post_type == 2 || item.post_type == 3)"
                      :disabled="item.is_replied === true"
                      class="btn customBtn pattern-btn text-capitalize white-text mt-2" @click="setDialog(true, item)">
                      <span> {{
                        item.owner == null ? $t("posts.replycall") : $t("posts.reply")
                      }}</span>
                    </v-btn> -->
                  </div>
                </div>
              </div>
              <!-- post_type=3 => text with image post -->
              <div v-if="item.post_type == 3" class="text-pre-wrap post-with-image rd-post-with-image">
                <div class="post-with-images-img" v-if="item.medias.length">
                  <img v-if="item?.medias[0]?.id == attentionPost.id" :src="attentionPost.url100" alt="">
                  <img v-else :src="item.medias[0].url100" @click="setImageSlider(item);" alt="">
                  <span v-if="item?.medias[0]?.id != attentionPost.id" class="click-on-it">Click on it</span>
                </div>
                <div class="post-with-images-text">
                  <span>{{ item.message.replace('$0.00', 'Call') }}</span>
                  <div class="text-right">
                    <!-- <v-btn height="35"
                      v-show="item.owner == null || item.owner.id != user.id && (item.post_type == 0 || item.post_type == 2 || item.post_type == 3)"
                      :disabled="item.is_replied === true"
                      class="btn customBtn pattern-btn text-capitalize white-text mt-2" @click="setDialog(true, item)">
                      <span> {{
                        item.owner == null ? $t("posts.replycall") : $t("posts.reply")
                      }}</span>
                    </v-btn> -->
                  </div>
                </div>
              </div>
              <div v-if="item.phone && item.post_type == 0" class="text-pre-wrap error--text">{{
                capitalizeFirstLetter(item.message.replace('$0.00', 'Call')) }}</div>
              <div v-if="item.phone && item.post_type == 1" class="text-pre-wrap blue--text">{{
                capitalizeFirstLetter(item.message.replace('$0.00', 'Call')) }}</div>
              <div v-if="!item.phone && item.post_type == 1" class="text-pre-wrap blue--text">{{
                capitalizeFirstLetter(item.message.replace('$0.00', 'Call')) }}</div>
                
              <div class="text-right">
                <v-btn
                  v-show="item.owner == null || item.owner.id != user.id && (item.post_type == 0 || item.post_type == 2 || item.post_type == 3)"
                  :disabled="item.is_replied === true" class="btn customBtn pattern-btn text-capitalize white-text mt-2"
                  @click="setDialog(true, item)">
                  {{
                    item.owner == null ? $t("posts.replycall") : $t("posts.reply")
                  }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <!-- <v-pagination v-model="page" :length="pageCount" @input="fetchPosts"></v-pagination> -->
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p>Showing {{ startEntry }} to {{ endEntry }} of {{ totalEntries }} entries</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  Previous
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  Next
                </button>
              </div>
            </div>
          </div>

          <v-dialog hide-overlay :attach="true" v-model="dialog.open" max-width="500px" content-class="login-modal">
            <!-- :content-class="
            dialog.title === $t('posts.createPost')
              ? 'createpost hide-overflow-y'
              : ''
          " -->
            <v-card>
              <v-card-title>
                <h4>{{ dialog.title }}</h4>
                <button class="close-btn" @click="dialog.open = !dialog.open">
                <img src="./../assets/img/close.svg" alt="">
              </button>
              </v-card-title>
              <v-card-text>
                <div class="post-reply-wrap">
                  <v-textarea v-show="dialog.title === $t('posts.createPost')" v-model="message"
                    :placeholder="$t('posts.typeYourMessageHere')" :error-messages="messageError"
                    @blur="$v.message.$touch()" outlined dense solo name="input-7-4" spellcheck="false">
                  </v-textarea>
                  <v-textarea v-show="dialog.title === $t('posts.replyOnPost')" v-model="reply"
                    :placeholder="$t('posts.enterText')" :error-messages="replyError" @blur="$v.reply.$touch()" outlined
                    dense solo name="input-7-4" spellcheck="false"></v-textarea>
                  <emoji-picker v-show="dialog.title === $t('posts.createPost')" @emoji="append" :search="search">
                    <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
                      @click.stop="clickEvent">
                      <svg class="emojipickers" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                      </svg>
                    </div>
                    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                      <div class="emoji-picker" :style="{
                        position: 'relative',
                        float: 'right',
                        bottom: '226px',
                      }">
                        <div class="emoji-picker__search">
                          <input type="text" v-model="search" v-focus />
                        </div>
                        <div>
                          <div v-for="(emojiGroup, category) in emojis" :key="category">
                            <h5>{{ category }}</h5>
                            <div class="emojis">
                              <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
                                :title="emojiName">
                                {{ emoji }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </emoji-picker>
                </div>
                
                <div v-if="adminTeamUser.includes(user.id)" v-show="dialog.title === $t('posts.createPost')" class="
                  flex
                  w-full
                  h-screen
                  items-center
                  justify-center
                  text-center
                  fileupload custom-fileUpload
                " id="app" style="min-height: 56px;margin-bottom:15px">
                  <v-progress-circular v-if="fileLoader" indeterminate :size="90" color="green"></v-progress-circular>
                  <div class="" v-if="!fileLoader">
                    <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose" :preview=false
                      :className="['d-none']" @input="onFileChange($event, 'img1')">
                      <label for="fileInput" slot="upload-label" class="node-image-uploader">
                        <figure>
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path class="path1"
                              d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                            </path>
                          </svg> -->
                          <img src="./../assets/img/dummy-img.svg" alt="" />
                        </figure>
                        <span class="upload-caption">Click to
                          upload</span>
                      </label>
                    </image-uploader>
                  </div>
                  <div id="img1" class="select-post-image">
                    <img v-if="image" class="img1" :src='image?.url100' alt='' style="border-radius: 8px;">
                  <a id="img1-delIcon" v-if="image" @click="deleteImage('img1')" class="delicon" data-tooltip="Delete">
                    <!-- <img src="./../assets/img/trash.svg" alt="" /> -->
                     <v-icon >mdi-delete</v-icon>
                  </a>
                </div>
                </div>
                <div v-if="adminTeamUser.includes(user.id)" class="checkbox-field">
                  <v-checkbox class="pr-0 pt-0" style="height: 25px" v-model="isAttentionPost"
                    :label="$t('posts.attentionPost')" @change="onCheckAttentionPost()"></v-checkbox>
                </div>
              </v-card-text>
              <v-card-actions>
                <img v-show="dialog.title === $t('posts.replyOnPost')" height="25" width="25"
                  src="./../assets/img/google_g_icon.svg" />
                <a v-show="dialog.title === $t('posts.replyOnPost')" :href="dialog.link" target="_blank"
                  class="google-text">
                  {{ $t("posts.googleReviews") }}
                </a>
                <v-spacer></v-spacer>
                <button class="btn customBtn red-fill-btn pattern-btn" @click="dialog.open = !dialog.open">
                  <span>{{ dialog.closeButtonText }}</span>
                </button>
                <button class="btn customBtn pattern-btn ml-2" elevation="0" :loading="loading"
                  @click="callDialogMethod" :disabled="(dialog.isForCreatePost &&
                    $v.message.$invalid &&
                    $v.message.$dirty) ||
                    (!dialog.isForCreatePost &&
                      $v.reply.$invalid &&
                      $v.reply.$dirty)
                    ">
                  <span> {{ dialog.saveButtonText }}</span>
                </button>
              </v-card-actions>
            </v-card>
          </v-dialog>

          
    <v-dialog hide-overlay :attach="true" v-model="imageSlider" max-width="95%" width="auto" margin="0">
      <v-carousel class="carousel-wrapper post-img-wrapper" :show-arrows="false" hide-delimiters height="auto">
        <span class="carousel-close-icon" @click="imageSlider = false">
          <v-icon>mdi-close</v-icon>
        </span>
        <v-carousel-item v-for="(item, i) in images" :key="i">
          <img :src="item.social" style="width: 100%; height: 100%" :alt="item.social" eager />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import dateUtil from "@/utils/date.utils";
import SocketMixin from "@/mixins/SocketMixin";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { DEBOUNCE_TIMEOUT } from "@/constants/common";
import { ITEMS_PER_PAGE, ADMIN_TEAM_USERS, ATTENTION_POST_IMAGE } from "@/constants/common";

export default {
  name: "Posts",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",

      images: [],
      imageSlider: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      dialog: {
        isForCreatePost: false,
        title: "",
        closeButtonText: "",
        saveButtonText: "",
        open: false,
        link: "",
      },
      selectedPost: {},
      loading: false,
      post: {
        message: null,
      },
      replyOnPostFields: {
        reply: null,
      },
      message: "",
      badgeCount: 0,
      reply: null,
      menu2: false,
      date: null,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      searchText: "",
      errors: {
        message: null,
        reply: null,
      },
      fileLoader: false,
      image: false,
      adminTeamUser: ADMIN_TEAM_USERS,
      isAttentionPost: false,
      attentionPost: ATTENTION_POST_IMAGE
    };
  },
  validations: {
    message: {
      required,
    },
    reply: {
      required,
    },
  },
  computed: {

    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },

    ...mapGetters({
      unReadMessagesCount: "messages/getUnReadMessagesCount",
      user: "user/getUser",
      posts: "posts/getPosts",
      postThreads: "postThread/getPostThreads",
      imageResponse: "posts/getImageResponse",
    }),
    messageError() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.message"),
          })
        );
      if (this.errors && this.errors.message) {
        return this.errors.message;
      }
      return errors;
    },
    replyError() {
      const errors = [];
      if (!this.$v.reply.$dirty) return errors;
      !this.$v.reply.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.reply"),
          })
        );
      if (this.errors && this.errors.reply) {
        return this.errors.reply;
      }
      return errors;
    },

    headers() {
      return [
        {
          text: this.$t("posts.dateTimeDismantlor"),
          align: "start",
          sortable: false,
          value: "created_at",
          width: "25%"
        },
        {
          text: this.$t("posts.postedMessages"),
          value: "message",
          // width: "58%",
        },
        // { text: this.$t("posts.dismenter"), value: "owner", width: "16%" },
        // { text: "", value: "data-table-expand" },
      ];
    },
  },

  async mounted() {
    await this.getUnReadMessagesCount();
    this.badgeCount = this.unReadMessagesCount;
    await this.fetchPosts();
  },

  methods: {
    ...mapActions({
      getUnReadMessagesCount: "messages/getUnReadMessagesCount",
      getPostThreads: "postThread/getPostThreads",
      getPosts: "posts/getPosts",
      createPost: "posts/createPost",
      createMessages: "messages/createMessages",
      replyMessages: "messages/replyMessages",
      uploadImage: "posts/uploadImage",
      deleteImage: "posts/deleteImage",
    }),

    // First letter  in caps : KK
    capitalizeFirstLetter(value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    },

    append(emoji) {
      this.message += emoji;
    },

    setDialog(isRepliedClicked, item = null) {
      this.selectedPost = item;
      if (!isRepliedClicked) {
        this.dialog.isForCreatePost = true;
        this.dialog.title = this.$t("posts.createPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("posts.cancel");
        this.dialog.saveButtonText = this.$t("posts.create");
      } else {
        this.dialog.isForCreatePost = false;
        this.dialog.title = this.$t("posts.replyOnPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("posts.cancel");
        this.dialog.saveButtonText = this.$t("posts.reply");
        this.dialog.link = this.selectedPost.owner
          ? this.selectedPost.owner.user_detail.company_reviews_link
          : "";
      }
    },

    callDialogMethod() {
      if (this.dialog.title === this.$t("posts.createPost")) {
        this.storePost();
      } else {
        this.replyOnPost(this.selectedPost);
      }
    },

    searchPosts: debounce(function () {
      this.fetchPosts();
    }, DEBOUNCE_TIMEOUT),



    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchPosts(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchPosts(); // Fetch new data based on the current page
      }
    },

    async fetchPosts() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.page = 1;
        }
        const startDate = this.date
          ? dateUtil.getStartDate(this.date)
          : dateUtil.getOneMonthBackDate();
        const endDate = this.date
          ? dateUtil.getEndDate(this.date)
          : dateUtil.getCurrentDate();
        await this.getPosts({
          page: this.currentPage,
          items_per_page: this.perPage,
          search: this.searchText,
          start_date: startDate,
          end_date: endDate,
        });
        if (this.posts.meta) {
          this.totalEntries = this.posts.meta.total;
          this.itemsPerPage = parseInt(this.posts.meta.per_page);
          this.pageCount = this.posts.meta.current_page;
        } else {
          this.itemsPerPage = this.posts.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async storePost() {
      this.$v.message.$touch();
      if (!this.$v.message.$invalid) {
        this.loading = true;
        try {
          var payload = { message: this.message };
          var media_ids = this.image ? this.image.id : '';
          if (media_ids != '') {
            payload = {
              ...payload,
              media_ids: [media_ids]
            }
          }
          await this.createPost(payload);
          this.message = "";
          this.$v.message.$reset();
          this.$refs['img1'].$el.children[1].value = "";
          this.image = false;
        } catch ({ message }) {
          this.errors.message = message.message;
        } finally {
          this.loading = false;
          this.dialog.open = false;
        }
      }
    },

    async replyOnPost(post) {
      this.$v.reply.$touch();
      if (!this.$v.reply.$invalid) {
        this.loading = true;
        try {
          if (!post.owner) {
            await this.replyMessages({
              post_id: post.id,
              body: this.reply,
            });
          } else {
            await this.createMessages({
              post_id: post.id,
              recipient_id: post.owner.id,
              body: this.reply,
            });
          }
          await this.fetchPosts();
          await this.getPostThreads({
            page: 1,
            items_per_page: ITEMS_PER_PAGE,
            search: "",
          });
          this.reply = "";
          this.expanded = [];
          this.$v.reply.$reset();
        } catch ({ message }) {
          this.errors.reply = message.body;
        } finally {
          this.dialog.open = false;
          this.loading = false;
        }
      }
    },

    setImageSlider(item) {
      if (item.post_type == 3) {
        item.medias[0].social = item.medias[0].url700;
      }
      this.imageSlider = true;
      this.images = item.medias;
    },

    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },

    async onCheckAttentionPost() {
      if (this.isAttentionPost) {
        this.image = ATTENTION_POST_IMAGE;
      } else {
        this.image = false;
      }
    },

    async saveImage(file, tag) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        await this.uploadImage(formData);
        this.fileLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if (this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    }
  },
};
</script>

<!-- <style scoped>
.v-dialog__content {
  position: initial;
}

.post-inventory-div {
  display: flex;
  align-items: center;
}
</style> -->
